import * as React from "react";
import * as styles from "./trial-page.module.css"
import "../i18n";
import Layout from "../components/Layout/Layout";
import {useTranslation} from "react-i18next";
import TrialHeader from "../components/TrialHeader/TrialHeader";
import TrialFeatures from "../components/TrialFeatures/TrialFeatures";
import Testimonial from "../components/Testimonial/Testimonial";
import TrialFunctionalities from "../components/TrialFunctionalities/TrialFunctionalities";
import TrialSimplePricing from "../components/TrialSimplePricing/TrialSimplePricing";
import StartButton from "../components/Start-Button/Start-button";
import Tick from "../components/TickComponent/Tick";
import tagIcon from "../../static/icons/check-green.svg"
import logo from "../images/logo.svg";

export default function FreeTrial() {
    const {t, i18n} = useTranslation();
    const testimonials = Array.from(t("trial-demo-page.testimonials_section.testimonials"));

    return (
        <Layout showNavbar={false} showFooter={false}>
            <div className={styles.logoSection}>
                <img src={logo} alt={"Hiringmaster logo"}/>
            </div>
            <TrialHeader textButton={t("trial-demo-page.start_free_trial")}
                         textAction={`${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}
                         tickText={t('no_credit_tag')}/>
            <TrialFeatures textButton={t("trial-demo-page.start_free_trial")}
                           textAction={`${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}/>
            <div className={styles.testimonial}>
                <Testimonial
                    text={testimonials[0].message}
                    avatar={testimonials[0].photo}
                    name={testimonials[0].author}
                    position={testimonials[0].position}
                />
            </div>
            <TrialFunctionalities/>
            <TrialSimplePricing isTrial={true}/>
            <div className={styles.testimonial}>
                <Testimonial
                    text={testimonials[1].message}
                    avatar={testimonials[1].photo}
                    name={testimonials[1].author}
                    position={testimonials[1].position}
                />
            </div>
            <div className={styles.freeTrialSection}>
                <div className={styles.freeTrialTexts}>
                    <h1 className={styles.freeTrialTitle}>{t('trial-demo-page.try_hiringmaster.title')}</h1>
                    <Tick tagIcon={tagIcon} text={t('trial-demo-page.try_hiringmaster.subtitle_1')}/>
                    <Tick tagIcon={tagIcon} text={t('trial-demo-page.try_hiringmaster.subtitle_2')}/>
                </div>
                <StartButton text={t("trial-demo-page.start_free_trial")}
                             to={`${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}
                             filled={true}
                             chevron={false}
                             filedColor={'#30BF78'}/>
            </div>
        </Layout>
    );
}
